<template>
    <styled-interface hide-toolbar>
        <styled-card
            hide-toggle
            centered>
            <template #heading>
                Ad Previews
            </template>

            <template #action-buttons>
                <div class="layout row hidden-sm-and-down">
                    <v-select
                        v-model="selected_ad_status"
                        :items="ad_status"
                        item-text="display_name"
                        item-value="api_name"
                        label="Ad Status"
                        class="styled-field mr-4" />

                    <v-select
                        v-model="selected_ad_layout"
                        :items="ad_layouts"
                        item-text="name"
                        return-object
                        label="Ad Placement"
                        class="styled-field mr-4"
                        :disabled="loading_ad_previews"
                        @change="fetchPreviews()" />

                    <styled-tooltip class="mr-4">
                        <action-button icon="question-circle">
                            No Ads
                        </action-button>
                        <template #content>
                            Please make sure that all ad blocking software is disabled and refresh the page.
                        </template>
                    </styled-tooltip>
                    <action-button
                        icon="refresh"
                        :loading="loading_ad_previews"
                        @click="fetchPreviews()">
                        Refresh
                    </action-button>
                </div>
            </template>

            <div class="flex hidden-md-and-up">
                <v-select
                    v-model="selected_ad_status"
                    :items="ad_status"
                    item-text="display_name"
                    item-value="api_name"
                    label="Ad Status"
                    class="styled-field pt-4 pb-2 px-3" />

                <v-select
                    v-model="selected_ad_layout"
                    :items="ad_layouts"
                    item-text="name"
                    return-object
                    label="Ad Placement"
                    class="styled-field py-2 px-3"
                    :disabled="loading_ad_previews"
                    @change="fetchPreviews()" />

                <div class="layout row space-between justify-space-around">
                    <styled-tooltip>
                        <action-button icon="question-circle">
                            No Ads
                        </action-button>
                        <template #content>
                            Please make sure that all ad blocking software is disabled and refresh the page.
                        </template>
                    </styled-tooltip>
                    <action-button
                        icon="refresh"
                        :loading="loading_ad_previews"
                        @click="fetchPreviews()">
                        Refresh
                    </action-button>
                </div>
            </div>

            <div>
                <v-layout
                    row
                    wrap>
                    <v-flex
                        v-for="ad in ads"
                        :key="ad.id"
                        lg6
                        xs12>
                        <styled-card
                            v-if="(selected_ad_status === 'ALL' || ad.effective_status === selected_ad_status) || (selected_ad_status === 'PAUSED' && ad.effective_status.includes('PAUSED'))"
                            centered
                            hide-toggle>
                            <template #heading>
                                {{ ad.name }}
                            </template>
                            <template #action-buttons>
                                <!-- <action-button
                                    icon="outbound-link"
                                    target="_blank"
                                    :href="'https://www.facebook.com/?feed_demo_ad='+ad.id">
                                    View on Facebook
                                </action-button> -->
                            </template>
                            <div>
                                <v-layout
                                    row
                                    justify-space-between
                                    class="pa-4">
                                    <v-flex
                                        xs6
                                        class="mb-3">
                                        <span class="grey--text text--lighten-1">{{ ad.id }}</span>
                                    </v-flex>
                                    <v-flex
                                        xs6
                                        class="text-xs-right mb-3">
                                        <ad-status-dots :status="ad.effective_status" />
                                    </v-flex>
                                </v-layout>
                                <div class="text-center pb-4">
                                    <span v-html="ad.body" />
                                </div>
                            </div>
                        </styled-card>
                    </v-flex>
                </v-layout>
            </div>

            <loader v-if="loading_ad_previews" />
        </styled-card>
    </styled-interface>
</template>

<script>
    import StyledCard from '../globals/StyledCard';
    import ActionButton from '../globals/ActionButton';
    import StyledInterface from '../globals/StyledInterface';
    import StyledTooltip from '../globals/StyledTooltip';
    import Loader from '../globals/Loader';
    import AdStatusDots from '../playbooks/AdStatusDots';
    import axios from 'axios';
    export default {
        title: 'Ad Previews',
        name: 'AdPreviews',
        components: {
            StyledInterface,
            StyledCard,
            ActionButton,
            AdStatusDots,
            StyledTooltip,
            Loader
        },
        data() {
            return {
                ad_ids: [],
                ad_account: null,
                agency_id: null,
                ads: [],
                loading_ad_previews: false,
                selected_ad_status: null,
                ad_status: [
                    {
                        'display_name': 'All',
                        'query_name': 'all',
                        'api_name': 'ALL'
                    },
                    {
                        'display_name': 'Active',
                        'query_name': 'active',
                        'api_name': 'ACTIVE'
                    },
                    {
                        'display_name': 'Paused',
                        'query_name': 'paused',
                        'api_name': 'PAUSED'
                    },
                    {
                        'display_name': 'Disapproved',
                        'query_name': 'disapproved',
                        'api_name': 'DISAPPROVED'
                    },
                    {
                        'display_name': 'Archived',
                        'query_name': 'archived',
                        'api_name': 'ARCHIVED'
                    }
                ],
                selected_ad_layout: null,
                ad_layouts: [
                    {
                        'name': 'Desktop News Feed',
                        'query_name': 'desktop_news_feed',
                        'api_name': 'DESKTOP_FEED_STANDARD',
                        'iframe_width': 502,
                        'iframe_height': 541
                    },
                    {
                        'name': 'Mobile News Feed',
                        'query_name': 'mobile_news_feed',
                        'api_name': 'MOBILE_FEED_STANDARD',
                        'iframe_width': 320,
                        'iframe_height': 470
                    },
                    {
                        'name': 'Facebook Story Mobile',
                        'query_name': 'facebook_story_mobile',
                        'api_name': 'FACEBOOK_STORY_MOBILE',
                        'iframe_width': 318,
                        'iframe_height': 566
                    },
                    {
                        'name': 'Marketplace Mobile',
                        'query_name': 'marketplace_mobile',
                        'api_name': 'MARKETPLACE_MOBILE',
                        'iframe_width': 180,
                        'iframe_height': 305
                    },
                    {
                        'name': 'Instagram Story',
                        'query_name': 'instagram_story',
                        'api_name': 'INSTAGRAM_STORY',
                        'iframe_width': 320,
                        'iframe_height': 567
                    },
                ]
            };
        },
        watch: {
            selected_ad_status() {
                this.ad_status.forEach(status => {
                    if (status.api_name === this.selected_ad_status) {
                        let query = Object.assign({}, this.$route.query, { status: status.query_name });
                        this.$router.push({ query });
                    }
                });
            },
            selected_ad_layout() {
                this.ad_layouts.forEach(layout => {
                    if (layout.api_name === this.selected_ad_layout.api_name) {
                        let query = Object.assign({}, this.$route.query, { placement: layout.query_name });
                        this.$router.push({ query });
                    }
                });
            }
        },
        async created() {
            await this.applyPlacementFilter();
            await this.applyStatusFilter();
            await this.fetchPreviews();
        },
        methods: {
            async fetchPreviews() {

                try {
                    this.loading_ad_previews = true;
                    this.ads = [];

                    //Get the data from the data storage endpoint
                    axios.defaults.headers.common['BB-Token'] = process.env.VUE_APP_BB_TOKEN;
                    const data_storage_response = await axios.get(process.env.VUE_APP_MVP_API_PUBLIC_BASE_URI + 'data-storage/ad_previews:' + this.$route.query.id);

                    //TODO check for errors in response here

                    let storage = data_storage_response.data.data;
                    this.agency_id = storage.agency_id;
                    this.ad_account = storage.ad_account;
                    this.ad_ids = storage.ad_ids;

                    for (const ad_id of this.ad_ids) {
                        await axios.post(process.env.VUE_APP_MVP_API_PUBLIC_BASE_URI + 'facebook/ad-account/previews', {
                            agency_id: this.agency_id,
                            ad_account: this.ad_account,
                            ad_format: this.selected_ad_layout.api_name,
                            ad_ids: ad_id,
                            width: this.selected_ad_layout.iframe_width,
                            height: this.selected_ad_layout.iframe_height
                        }).then(response => {
                            this.ads.push(response.data[0]);
                        }).catch(error => {
                            console.log(error);
                        });
                    }

                    this.loading_ad_previews = false;

                } catch (e) {
                    //TODO show error
                    this.loading_ad_previews = false;
                    console.log(e);
                }
            },
            async applyPlacementFilter() {
                //Check for query pram in url and set up selected_ad_layout filter
                this.ad_layouts.forEach(layout => {
                    if (layout.query_name === this.$route.query.placement) {
                        this.selected_ad_layout = layout;
                    }
                });
                //Check if no query pram is present and set a default
                if (!this.selected_ad_layout) {
                    this.selected_ad_layout = this.ad_layouts[0]; //This will set the default filter to all and sync the url pram with the selected_ad_status watcher
                }
            },
            async applyStatusFilter() {
                //Check for query pram in url and set up selected_ad_status filter
                this.ad_status.forEach(status => {
                    if (status.query_name === this.$route.query.status) {
                        this.selected_ad_status = status.api_name;
                    }
                });
                //Check if no query pram is present and set a default
                if (!this.selected_ad_status) {
                    this.selected_ad_status = 'ALL'; //This will set the default filter to all and sync the url pram with the selected_ad_status watcher
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
.styled-card.centered {
    margin: 12px 4px;
    @media (min-width: $bp-md) {
        margin: 24px;
    }
}
</style>